import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { Task } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { CreateTaskRequest } from 'src/app/shared/models/task/task-request';

@Component({
  selector: 'task-duplicate',
  templateUrl: './task-duplicate.component.html',
  styleUrls: ['./task-duplicate.component.scss']
})
export class TaskDuplicateComponent {
  tenantId = this._tenantService.getTenantId();
  @Input() task: Task;
  taskDuplicate: Task;

  options: DropdownOption[];

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _translate: TranslateService,
    private _router: Router,
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit(): void {
    this.options = this._translate.instant('TASKS.TASK_DUPLICATE_ACTIONS');
    this.taskDuplicate = JSON.parse(JSON.stringify(this.task));
  }

  onSelectedOptionChange($event: DropdownOption) {
    if (this.task.expirationDate === null || this.task.usageStartDate === null || this.task.usageEndDate === null) {
      return;
    }

    switch ($event.value) {
      case 'one_month':
        this.addTime(30);
        break;
      case 'six_month':
        this.addTime(180);
        break;
      case 'one_year':
        this.addTime(365);
        break;
      case 'two_years':
        this.addTime(730);
        break;
      case 'four_years':
        this.addTime(1460);
        break;
    }

    this.createDuplicateTask()
  }

  private addTime(days: number) {
    let startDate = new Date(this.task.usageStartDate);
    let endDate = new Date(this.task.usageEndDate);

    startDate = new Date(startDate.setDate(startDate.getDate() + days));
    endDate = new Date(endDate.setDate(endDate.getDate() + days));

    let expirationDate = new Date(endDate.setDate(endDate.getDate() - 1));

    this.taskDuplicate.usageStartDate = startDate.toISOString();
    this.taskDuplicate.usageEndDate = endDate.toISOString();
    this.taskDuplicate.expirationDate = expirationDate.toISOString();
  }

  private createDuplicateTask() {
    let request: CreateTaskRequest = this._taskService.mapTaskToTaskRequest(this.taskDuplicate);
    request.code = null;
    this._taskService.createTask$(this.tenantId, request).subscribe({
      next: response => {
        let queryParams: Params = { modalEditTask: response.id };
        this._router.navigate([window.location.pathname], { queryParams });
        this._communicationService.sendEvent();
      }
    });
  }
}