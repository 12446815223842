import * as Sentry from "@sentry/angular-ivy";
import * as StatusBooking from 'src/app/shared/constant/status-booking';
import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Booking } from 'src/app/shared/models/booking/booking';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from "../../dropdown/dropdown-primary/dropdown.interface";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { TypeExtraField } from "src/app/shared/models/common";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";

@Component({
  selector: 'card-booking-info',
  templateUrl: './card-booking-info.component.html',
  styleUrls: ['./card-booking-info.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class CardBookingInfoComponent {

  @Input() bookingId: string;
  booking: Booking;

  @Input() isFleetManager: boolean = false;
  isMobile: boolean = false;
  isDisableStatus: boolean;

  modalCreateBooking: BsModalRef;
  tenantId: string;
  optionStatusBooking: DropdownOption;
  listStatus: DropdownOption[] = [
    { text: this._translateService.instant('BOOKING_STATUS.APPROVE'), value: 'approve' },
    { text: this._translateService.instant('BOOKING_STATUS.REJECT'), value: 'unapprove' },
  ];


  dropdownApprovationCssClass: string;
  iconForStatus: string;

  typeExtraFields: TypeExtraField[];
  extraFields: any;
  objectKeys = Object.keys;

  constructor(
    private _modalUtilsService: ModalUtilsService,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _comunicationService: CommunicationService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
  ) {
    this.tenantId = this._tenantService.getTenantId();
  }

  ngOnInit() {
    if (this.isFleetManager) {
      this.iconForStatus = "bi bi-caret-down-fill";
    }
    this.getBooking();
  }

  private getBooking() {
    if (this.bookingId) {
      let params = {
        includeUser: true,
        includeVehicle: true,
        includeLocation: true,
        includeHeadquarter: true,
        includeBookingType: true,
        includeInsertedByUser: true,
      };

      this._bookingService.getBooking$(this.tenantId, this.bookingId, params).subscribe({
        next: (booking: Booking) => {
          this.booking = booking;

          this.getExtraFields();
          this.getStatusBooking();

          if (this.booking.status === 'OK' || booking.status.split('_')[0] === 'UNAVAILABLE') {
            this.isDisableStatus = true;
          }
        }
      });
    }
  }

  getStatusBooking(): void {
    if (this.booking.status === StatusBooking.STATUS_PENDING) {
      this.optionStatusBooking = { text: this._translateService.instant('BOOKING_STATUS.TO_BE_APPROVED'), value: 'toBeApproved' };
      this.dropdownApprovationCssClass = 'bg-warning'
      return;
    }
    else {
      this.isDisableStatus = true;
    }

    if (this.booking.status !== StatusBooking.STATUS_REJECTED) {
      this.optionStatusBooking = { text: this._translateService.instant('BOOKING_STATUS.APPROVED'), value: 'approve' };
      this.dropdownApprovationCssClass = 'bg-success'
    }

    if (this.booking.status === StatusBooking.STATUS_REJECTED) {
      this.optionStatusBooking = { text: this._translateService.instant('BOOKING_STATUS.REJECTED'), value: 'unapprove' };
      this.dropdownApprovationCssClass = 'bg-black'
    }
  }

  openModalEditBooking() {
    this._modalUtilsService.openBookingModal(this.booking, this.isFleetManager).subscribe(modalRef => {
      this.modalCreateBooking = modalRef;

      this.modalCreateBooking.onHidden.subscribe(() => {
        let params = {
          includeUser: true,
          includeVehicle: true,
          includeLocation: true,
          includeHeadquarter: true,
          includeBookingType: true,
          includeInsertedByUser: true,
        };

        this._bookingService.getBooking$(this.tenantId, this.booking.id, params).subscribe({
          next: (booking: Booking) => {
            this.booking = booking;
          },
          error: (error: any) => {
            Sentry.captureEvent(error);
          }
        });
      });
    });
  }

  onBookingStatusChange($event: DropdownOption) {
    if ($event.value === 'approve') {
      this._bookingService.approveBooking$(this.tenantId, this.booking.id).subscribe({
        next: (response) => {
          if (response.status == 200) {
            this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_APPROVED'));
            this._comunicationService.sendEvent();
            this.ngOnInit();
          }
        }
      });
    }

    if ($event.value === 'unapprove') {
      this._bookingService.unapproveBooking$(this.tenantId, this.booking.id).subscribe({
        next: (response) => {
          if (response.status == 200) {
            this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_UNAPPROVED'));
            this._comunicationService.sendEvent();
            this.ngOnInit();
          }
        }
      });
    }
  }

  getExtraFields() {
    if (this.booking?.bookingTypeId !== null && this.booking?.extraFields !== null) {
      this.extraFields = JSON.parse(JSON.stringify(this.booking.extraFields));

      this._bookingService.getBookingFields$(this.tenantId, { includeBookingTypeId: this.booking.bookingTypeId }).subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);

          this.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.extraFields);
          this.extraFields = this._extraFieldsUtilsService.parseBoolName(this.typeExtraFields, this.extraFields);

        },
        error: (error: any) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

}
