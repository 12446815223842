import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap, Subject, takeUntil } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehicleResponse } from 'src/app/shared/models/vehicle/vehicle-response';
import { FilterModalService, GenericFilter } from '../filter-modal.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { HeadquarterFilter } from 'src/app/shared/models/headquarter/headquarter-filter';
import { HeadquarterResponse } from 'src/app/shared/models/headquarter/headquarter-response';
import { LocationResponse } from 'src/app/shared/models/location/location-response';
import { LocationFilter } from 'src/app/shared/models/location/location-filter';
import { UsersFilter } from 'src/app/shared/models/user/user-filter';
import { VehicleFilter } from 'src/app/shared/models/vehicle/vehicle-filter';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const filterVehicleUsagesModal = 'filter-vehicle-usages-modal';

@Component({
  selector: 'filter-vehicle-usages-modal',
  templateUrl: './filter-vehicle-usages-modal.component.html',
  styleUrls: ['./filter-vehicle-usages-modal.component.scss']
})
export class FilterVehicleUsagesModalComponent implements OnInit, OnDestroy {
  private tenantId = this._tenantService.getTenantId();

  form: FormGroup;
  genericFilter: GenericFilter = {};

  vehicleDisplayName$: Observable<string[]>;
  vehicleLicensePlate$: Observable<string[]>;
  userDisplayName$: Observable<string[]>;
  userStartDisplayName$: Observable<string[]>;
  userEndDisplayName$: Observable<string[]>;
  headquarterName$: Observable<string[]>;
  locationName$: Observable<string[]>;

  private _destroy$ = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _usersService: UsersService,
    private _headquartersService: HeadquartersService,
    private _filterModalService: FilterModalService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleDisplayName: new FormControl(null),
      vehicleLicensePlate: new FormControl(null),
      userDisplayName: new FormControl(null),
      userStartDisplayName: new FormControl(null),
      userEndDisplayName: new FormControl(null),
      headquarterName: new FormControl(null),
      locationName: new FormControl(null),
      closeOnApply: new FormControl(true),
    });
  }

  ngOnInit() {
    this.autoComplete();
    this.genericFilter = this._filterModalService.getFilter(filterVehicleUsagesModal);
  }

  private autoComplete() {
    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { displayNameContains: token, locationId: this.genericFilter?.location?.id ?? null, limit: 5 } as VehicleFilter)),
      map((vehicle: VehicleResponse) => {
        let displayNames: string[] = [];
        vehicle.items.forEach((vehicle) => {
          displayNames.push(vehicle.displayName);
        });
        return displayNames;
      }),
    );

    this.vehicleLicensePlate$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleLicensePlate'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { licensePlateContains: token, locationId: this.genericFilter?.location?.id ?? null, limit: 5 } as VehicleFilter)),
      map((vehicle: VehicleResponse) => {
        let displayNames: string[] = [];
        vehicle.items.forEach((vehicle) => {
          displayNames.push(vehicle.licensePlate);
        });
        return displayNames;
      }),
    );

    this.userDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['userDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._usersService.listUsers$(this.tenantId,
        { displayNameContains: token, limit: 5 } as UsersFilter)),
      map((user) => {
        let displayNames: string[] = [];
        user.items.forEach((user) => {
          displayNames.push(user.displayName);
        });
        return displayNames;
      }),
    );

    this.userStartDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['userStartDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._usersService.listUsers$(this.tenantId,
        { displayNameContains: token, limit: 5 } as UsersFilter)),
      map((user) => {
        let displayNames: string[] = [];
        user.items.forEach((user) => {
          displayNames.push(user.displayName);
        });
        return displayNames;
      }),
    );

    this.userEndDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['userEndDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._usersService.listUsers$(this.tenantId,
        { displayNameContains: token, limit: 5 } as UsersFilter)),
      map((user) => {
        let displayNames: string[] = [];
        user.items.forEach((user) => {
          displayNames.push(user.displayName);
        });
        return displayNames;
      }),
    );

    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._headquartersService.listHeadquarters$(this.tenantId,
        { nameContains: token, limit: 5 } as HeadquarterFilter)),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );

    this.locationName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['locationName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
      switchMap((token: string) => this._headquartersService.listLocations$(this.tenantId,
        { nameContains: token, headquarterId: this.genericFilter?.headquarter?.id ?? null, limit: 5 } as LocationFilter)),
      map((response: LocationResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((location) => {
          displayNames.push(location.name);
        });
        return displayNames;
      }),
    );
  }

  onSelectVehicleDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;
    this._vehiclesService.listVehicles$(this.tenantId, { displayName } as VehicleFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              vehicleDisplayName: response.items[0].displayName,
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.vehicle = response.items[0];
          }
        }
      });
  }

  onSelectVehicleLicensePlate(match: TypeaheadMatch) {
    const licensePlate = match.value;
    this._vehiclesService.listVehicles$(this.tenantId, { licensePlate } as VehicleFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              vehicleLicensePlate: response.items[0].licensePlate
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.vehicle = response.items[0];
          }
        }
      });
  }

  onSelectUserDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;
    this._usersService.listUsers$(this.tenantId, { displayName } as UsersFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              userDisplayName: response.items[0].displayName
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.user = response.items[0];
          }
        }
      });
  }

  onSelectUserStartDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;
    this._usersService.listUsers$(this.tenantId, { displayName } as UsersFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              userStartDisplayName: response.items[0].displayName
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.startUser = response.items[0];
          }
        }
      });
  }

  onSelectUserEndDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;
    this._usersService.listUsers$(this.tenantId, { displayName } as UsersFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              userEndDisplayName: response.items[0].displayName
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.endUser = response.items[0];
          }
        }
      });
  }

  onSelectHeadquarterName(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listHeadquarters$(this.tenantId, { name } as HeadquarterFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              headquarterName: response.items[0].name
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.headquarter = response.items[0];
          }
        }
      });
  }

  onSelectLocationName(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listLocations$(this.tenantId, { name, includeHeadquarter: true } as LocationFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              locationName: response.items[0].name
            });
            this.genericFilter = this.genericFilter ?? {};
            this.genericFilter.location = response.items[0];
          }
        }
      });
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    this._filterModalService.applyFilter(filterVehicleUsagesModal, this.genericFilter);
    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.genericFilter = {};
      this.form.reset();
    }
  }

  close() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}