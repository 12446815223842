import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { Task } from 'src/app/shared/models/task/task';
import { TasksFilter } from 'src/app/shared/models/task/task-filter';
import { UserModel } from 'src/app/shared/models/user/users';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { CreateTaskRequest } from 'src/app/shared/models/task/task-request';

@Component({
  selector: 'task-manage-follower-modal',
  templateUrl: './task-manage-follower-modal.component.html',
  styleUrls: ['./task-manage-follower-modal.component.scss']
})
export class TaskManageFollowerModalComponent {

  tenantId = this._tenantService.getTenantId();
  @Input() taskId: string;
  task: Task;
  taskRequest: CreateTaskRequest;

  form = new FormGroup({
    userName: new FormControl(null, Validators.required),
    usersForm: new FormGroup({})
  });

  userName$: Observable<string[]>;

  selectedUsers: UserModelClient[] = [];

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _usersService: UsersService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.setAutocomplete();
    this.getFollowers();

    this._communicationService.getSpecificEvent(this.taskId).subscribe({
      next: () => {
        this.getFollowers();
      }
    });
  }

  private getFollowers() {
    let params: TasksFilter = {
      includeTaskWatchers: true,
    };
    if (this.taskId) {
      this._taskService.getTask$(this.tenantId, this.taskId, params).subscribe({
        next: task => {
          this.task = task;
          this.taskRequest = this._taskService.mapTaskToTaskRequest(this.task);
          this.setFollowers();
        }
      });
    }
  }

  private setFollowers() {
    if (this.task.taskWatchers) {
      this.task.taskWatchers.forEach(watcher => {
        let follower = watcher as UserModelClient;
        follower.isFollower = true;
        this.selectedUsers.push(follower);

        const userFormGroup = new FormGroup({
          isFollower: new FormControl(true)
        });
        (this.form.get('usersForm') as FormGroup).addControl(follower.id.toString(), userFormGroup);
      });
    }
  }

  private setAutocomplete() {
    this.userName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['userName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._usersService.listUsers$(this.tenantId,
        { displayNameContains: token, limit: 5, taskTypeId: this.task.taskTypeId })),
      map((users) => {
        let userNames: string[] = [];
        users.items.forEach((user) => {
          userNames.push(user.displayName);
        });
        return userNames;
      })
    );
  }

  onSelectUser(match: TypeaheadMatch) {
    const displayName = match.value;
    const userExist = this.selectedUsers.some(user => user.displayName === displayName);

    if (!userExist) {
      this._usersService.listUsers$(this.tenantId, { displayName: displayName }).subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            const user = response.items[0] as UserModelClient;
            user.isFollower = true;
            this.selectedUsers.push(user);

            const userFormGroup = new FormGroup({
              isFollower: new FormControl(true)
            });
            (this.form.get('usersForm') as FormGroup).addControl(user.id.toString(), userFormGroup);
            this.form.controls['userName'].setValue(null);
          }
        }
      });
    }
  }

  apply() {
    let followerIds: string[] = [];
    this.selectedUsers.forEach(user => {
      const userForm = (this.form.get('usersForm') as FormGroup).get(user.id.toString()) as FormGroup;
      if (userForm.get('isFollower').value) {
        followerIds.push(user.id);
      }
    });
    this.taskRequest.taskWatcherIds = followerIds;

    this._taskService.patchTask$(this.tenantId, this.task.id, this.taskRequest).subscribe({
      next: (response) => {
        if (response?.id) {
          this._communicationService.sendSpecificEvent(response.id);
          this._communicationService.sendSpecificEventWithData(response.id, response);
          this.bsModalRef.hide();
        }
      }
    });
  }
}

export interface UserModelClient extends UserModel {
  isFollower: boolean;
}
