import * as Sentry from "@sentry/angular-ivy";
import * as Permissions from 'src/app/shared/constant/permissions';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { Task, TaskClient, TaskData, TaskDataClient, TaskType } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';
import { MappingModel } from 'src/app/shared/models/user/users';
import { TableColumn } from 'src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from 'src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-row.interface';
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { Router } from "@angular/router";
import { TaskDataFilter, TasksFilter } from "src/app/shared/models/task/task-filter";
import { DuplicateTaskModalComponent } from "../../modals/duplicate-task-modal/duplicate-task-modal.component";
import { Me } from "src/app/shared/models/me/me";
import { MeService } from "src/app/shared/api-services/me.service";

@Component({
  selector: 'cost-table',
  templateUrl: './cost-table.component.html',
  styleUrls: ['./cost-table.component.scss']
})
export class CostTableComponent implements OnInit, OnDestroy {
  tenantId: string;
  @Input() tableId: string;

  @Input() titleTable: string;
  @Input() listTableFilterTasks!: DropdownOption[];

  @Input() taskTypeId?: string;
  @Input() vehicleId?: string;
  @Input() userId?: string;
  @Input() assetId?: string;
  @Input() bookingId?: string;

  me?: Me;

  @Input() hasAsset?: boolean;
  @Input() hasUsage?: boolean;

  taskFilter: TasksFilter = {
    includeTaskType: true,
    includeHeadquarter: true,
    includeVehicle: true,
    includeUser: true,
    includeAsset: true,
    includeDeleted: true,
    includeAssignedUser: true,
    includeTaskCost: true,
    hasTCO: true
  };

  tableColumns: TableColumn[];
  tableRows: TableRow[];

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    maxPagesToShowPagination: 6
  };

  filterHeader: string;
  filterTask: DropdownOption;
  typeTable: string;

  isMobile: boolean = false;
  hasManageCostsPermission: boolean = false;

  confirmDeleteModal: BsModalRef;
  duplicateTaskModal: BsModalRef;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _meService: MeService,
    private _translateService: TranslateService,
    private _datePipe: DatePipe,
    private _taskUtilsService: TaskUtilsService,
    private _dropdownEventsService: DropdownEventsService,
    private _filterModalService: FilterModalService,
    private _toastrService: ToastrService,
    private _modalUtilsService: ModalUtilsService,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
  ) {
    this.tenantId = this._tenantService.getTenantId();
    this.getPermissions();
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.applyFilterFromModal();

    if (this.me == null && !this.userId) {
      this._meService.getMe$(this.tenantId)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (me) => {
            this.me = me;
          }
        });
    }

    this._communicationService.getEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.getCosts();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  getPermissions() {
    this._meService.getMePermissions$(this.tenantId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.hasManageCostsPermission = response.permissions.includes(Permissions.CAN_MANAGE_COSTS);
          if (!this.hasManageCostsPermission) {
            this.tableColumns = this.tableColumns.filter(column => column.field !== 'actionsDynamic');
          }
        }
      });
  }

  getTable($typeTable?: string) {
    if ($typeTable) {
      this.typeTable = $typeTable;
    }

    if (this.typeTable === 'all_tasks' || this.typeTable === 'assigned_to_you' || this.typeTable === 'created_by_you') {
      this.filtersPage.orderBy = '-Id';

      this.getCosts();

      this.tableColumns = [];
      if (this.isMobile) {
        this.tableColumns = [
          {
            field: 'title',
            header: "TITLE",
            isSortable: true,
            cssClass: () => 'cursor-pointer',
            routerLink: () => `/${this._router.url}`,
            queryParams: (task: Task) => ({ modalEditTask: task.id })
          },
          {
            field: 'actionsDynamic',
            header: "ACTIONS.NAME",
            dotOptions: (task: TaskClient) => task.actions
          }
        ];

      }
      else {
        this.tableColumns = [
          {
            field: 'title',
            header: "TITLE",
            isSortable: true,
            cssClass: () => 'cursor-pointer',
            routerLink: () => `/${this._router.url}`,
            queryParams: (task: Task) => ({ modalEditTask: task.id })
          },
          {
            field: 'taskTypeId',
            header: "TYPOLOGY",
            isSortable: true,
            value: (task: Task) => task.taskType ? this._translateService.instant(task.taskType.name) : ''
          },
          {
            field: 'expirationDate',
            header: "BY_THE_DATE",
            isSortable: true,
            value: (task: Task) => task.expirationDate ? this._datePipe.transform(task.expirationDate, 'dd-MM-yyyy')?.toString() : ''
          },
          {
            field: 'vehicleId',
            header: "LICENSE_PLATE",
            isSortable: true,
            value: (task: Task) => task.vehicle ? task.vehicle.licensePlate : ''
          },
          {
            field: 'assetId',
            header: "ASSET",
            isSortable: true,
            value: (task: Task) => task.asset ? task.asset.displayName : ''
          },
          {
            field: 'userId',
            header: 'USER',
            isSortable: true,
            value: (task) => task.user ? task.user.displayName : ''
          },
          {
            field: 'headquarterId',
            header: "HEADQUARTER.NAME",
            isSortable: true,
            value: (task: Task) => task.headquarter ? task.headquarter.name : ''
          },
          {
            field: 'accountingDate',
            header: "COSTS.DATE_ACCOUNTING",
            isSortable: true,
            value: (task: Task) => task.taskCost ? this._datePipe.transform(task.taskCost.accountingDate, 'dd-MM-yyyy')?.toString() : ''
          },
          {
            field: 'netCost',
            header: "NetCost",
            isSortable: true,
            value: (task: Task) => task.taskCost ? task.taskCost.netCost : ''
          },
          {
            field: 'grossCost',
            header: "GrossCost",
            isSortable: true,
            value: (task: Task) => task.taskCost ? task.taskCost.grossCost : ''
          },
          {
            field: 'status',
            header: "STATUS",
            value: (task: TaskClient) => this._taskUtilsService.setStatusBadgeTask(task).statusClient?.text,
            cssClass: (task: TaskClient) => this._taskUtilsService.setStatusBadgeTask(task).statusClient?.cssClass,
          },
          {
            field: 'actionsDynamic',
            header: "ACTIONS.NAME",
            dotOptions: (task: TaskClient) => task.actions
          },
        ];

      }

    }

    if (this.typeTable == 'by_type') {
      this.filtersPage.orderBy = '-Items';
      this.getNumberTypeCosts();

      this.tableColumns = [
        {
          field: 'taskTypeId',
          header: "TYPOLOGY",
          isSortable: true,
          value: (taskTypeResp: TaskData) => taskTypeResp.taskType?.name ? this._translateService.instant(taskTypeResp.taskType.name) : ''
        },
        {
          field: 'items',
          header: "Item",
          isSortable: true,
          value: (taskType: TaskData) => taskType.items ? taskType.items : '0'
        },
        {
          field: 'withVehicle',
          header: "VEHICLESS",
          isSortable: true,
          value: (taskType: TaskData) => taskType.withVehicle ? taskType.withVehicle : '0'
        },
        {
          field: 'withAsset',
          header: "ASSET",
          isSortable: true,
          value: (taskType: TaskData) => taskType.withAsset ? taskType.withAsset : '0'
        },
        {
          field: 'withUser',
          header: "PERSONS.NAME",
          isSortable: true,
          value: (taskType: TaskData) => taskType.withUser ? taskType.withUser : '0'
        },
        {
          field: 'quota',
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.quota",
          value: (taskType: TaskDataClient) => taskType.percentage ? `${taskType.percentage}%` : '0.00%'
        },
      ];
    }
    this._cdr.detectChanges();
  }

  getCosts() {
    this.taskFilter.start = this.filtersPage.firstElement;
    this.taskFilter.limit = this.filtersPage.itemsPerPage;
    this.taskFilter.orderBy = this.filtersPage.orderBy;

    this.taskFilter.vehicleId = this.vehicleId ?? null;
    this.taskFilter.bookingId = this.bookingId ?? null;
    this.taskFilter.userId = this.userId ?? null;
    this.taskFilter.assetId = this.assetId ?? null;
    this.taskFilter.taskTypeId = this.taskTypeId ?? null;

    this.taskFilter.hasAsset = this.hasAsset ?? null;
    this.taskFilter.hasUsage = this.hasUsage ?? null;

    if (this.typeTable === 'assigned_to_you') {
      this.taskFilter.assignedUserId = this.me.id;
    } else {
      this.taskFilter.assignedUserId = null;
    }

    if (this.typeTable === 'created_by_you') {
      this.taskFilter.startUserId = this.me.id;
    } else {
      this.taskFilter.startUserId = null;
    }

    if (this.typeTable === 'all_tasks' && this.userId) {
      this.taskFilter.userId = this.userId;
    } else {
      this.taskFilter.userId = null;
    }

    this._taskService.listTasks$(this.tenantId, this.taskFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.tableRows?.forEach(row => this._dropdownEventsService.removeSubscriptions(row['customId']));
          this.tableRows = response.items.map((task: TaskClient) => {
            task.customId = this.tableId + task.id;
            return this._taskUtilsService.setActionsTask(task);
          });

          this.onActionTable();

          this.filtersPage.totalRows = response.count;
          this.filtersPage.totalPage = Math.ceil(response.count / this.filtersPage.itemsPerPage);
        }
      });
  }

  getNumberTypeCosts() {
    let listTaskType: TaskType[] = [];

    let query: TaskDataFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
      includeTaskType: true,
      includeTaskData: true,
      includeTaskUsage: true,
      hasTCO: true,
      groupByTaskTypeId: '*',
    };

    if (this.taskFilter.rangeExpirationStartDate) {
      query.rangeStartExpirationDate = new Date(new Date(this.taskFilter.rangeExpirationStartDate).setHours(0, 0, 0, 0)).toISOString();
    }

    if (this.taskFilter.rangeExpirationEndDate) {
      query.rangeEndExpirationDate = new Date(new Date(this.taskFilter.rangeExpirationEndDate).setHours(23, 59, 59, 999)).toISOString();
    }

    this._taskService.listTaskTypes$(this.tenantId).pipe(
      takeUntil(this._destroy$),
      switchMap((taskTypeResp) => {
        listTaskType = taskTypeResp.items;

        return this._taskService.getTaskData$(this.tenantId, query)
      })
    )
      .subscribe({
        next: (taskDataResp) => {
          this.tableRows = [];

          this.filtersPage.totalRows = taskDataResp.count;
          this.filtersPage.totalPage = Math.ceil(taskDataResp.count / this.filtersPage.itemsPerPage);

          const totalTasks = taskDataResp.items.reduce((accumulator, current) => accumulator + current.items, 0);
          listTaskType.forEach((taskType) => {

            let taskData: TaskDataClient = taskDataResp.items.find(item => item.taskTypeId === taskType.id);
            if (taskData) {
              taskData.percentage = (taskData.items / totalTasks * 100).toFixed(2);
            }
          });
          this.tableRows = taskDataResp.items;
        }
      });

  }

  onActionTable() {
    this.tableRows.forEach((row) => {
      this._dropdownEventsService.getSelectedOptionOnClick(row['customId'])
        .pipe(takeUntil(this._destroy$))
        .subscribe((option: DropdownOption) => {
          if (option && option.value === 'cancel') {
            let confirmDeleteModal = this._modalService.show(ConfirmModalComponent, {
              class: 'modal-sm',
              animated: true,
              initialState: {
                data: {
                  content: 'MESSAGES.CONFIRM_DELETE'
                }
              }
            });
            confirmDeleteModal.content.onConfirm.pipe(
              takeUntil(this._destroy$),
              switchMap(() => this._taskService.deleteTask$(this.tenantId, row.id))
            ).subscribe({
              next: () => {
                this.getTable(this.typeTable);
              }
            });
          }

          if (option && option.value === 'uncancel') {
            this._taskService.unDeleteTask$(this.tenantId, row.id)
              .pipe(takeUntil(this._destroy$))
              .subscribe({
                next: () => {
                  this.getTable(this.typeTable);
                }
              });
          }

          if (option && option.value === 'edit') {
            this._modalUtilsService.openEditTaskModal(row.id)
              .pipe(takeUntil(this._destroy$))
              .subscribe();
          }

        });
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmDeleteModal = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          data: {
            content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', { x: elementsSelected.length }),
          }
        }
      });

      this.confirmDeleteModal.content.onConfirm
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._taskService.deleteTask$(this.tenantId, elementsSelected[index].id)
              .pipe(takeUntil(this._destroy$))
              .subscribe({
                next: (response) => {
                  if (response.status == 204) {
                    if (index === elementsSelected.length - 1) {
                      this.getData();
                    }
                    this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DELETE_TASK'));
                  }
                }
              });
          }
        });
    }

    if ($event === 'duplicate_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      if (elementsSelected.length > 0) {

        if (elementsSelected.some(element => element['deleted'])) {
          this._toastrService.warning(this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_DELETED_TASK'));
          return;
        }

        this.duplicateTaskModal = this._modalService.show(DuplicateTaskModalComponent, {
          class: 'modal-md',
          animated: true,
          initialState: {
            elementsSelected: elementsSelected
          }
        });

        this.duplicateTaskModal.content.hide.pipe(takeUntil(this._destroy$)).subscribe(() => {
          this.getData();
        });
      }
      else {
        this._toastrService.warning(this._translateService.instant('TASKS.MESSAGE.SELECT_AT_LEAST_ONE_TASK'));
      }
    }
  }

  private applyFilterFromModal() {
    this._filterModalService.getForm$('filter-cost-modal')
      .pipe(takeUntil(this._destroy$))
      .subscribe(form => {
        if (form) {
          this.taskTypeId = form.value.taskTypeId ?? null;
          this.vehicleId = form.value.vehicleId ?? null;
          this.getData();
        }
      });
  }

  getDataForm($event: FormGroup) {
    if ($event.value.headerToFilter && $event.value.headerToFilter === 'Title') {
      this.taskFilter.titleContains = $event.value.inputFilter;
    } else {
      this.taskFilter.titleContains = null;
    }

    if ($event.value.headerToFilter && $event.value.headerToFilter === 'TaskCode') {
      this.taskFilter.code = $event.value.inputFilter;
    } else {
      this.taskFilter.code = null;
    }

    if ($event.value.typeDate === 'enter') {
      if ($event.value.startDate) {
        let startDate = new Date($event.value.startDate);
        startDate.setHours(0, 0, 0, 0);
        this.taskFilter.rangeExpirationStartDate = startDate.toISOString();
      } else {
        this.taskFilter.rangeExpirationStartDate = null;
      }

      if ($event.value.endDate) {
        let endDate = new Date($event.value.endDate);
        endDate.setHours(23, 59, 59, 999);
        this.taskFilter.rangeExpirationEndDate = endDate.toISOString();
      } else {
        this.taskFilter.rangeExpirationEndDate = null;
      }
    }

    if ($event.value.typeDate === 'accountingDate') {
      if ($event.value.startDate) {
        let startDate = new Date($event.value.startDate);
        startDate.setHours(0, 0, 0, 0);
        this.taskFilter.rangeAccountingStartDate = startDate.toISOString();
      } else {
        this.taskFilter.rangeAccountingStartDate = null;
      }

      if ($event.value.endDate) {
        let endDate = new Date($event.value.endDate);
        endDate.setHours(23, 59, 59, 999);
        this.taskFilter.rangeAccountingEndDate = endDate.toISOString();
      } else {
        this.taskFilter.rangeAccountingEndDate = null;
      }
    }

    this.getData();
  }

  onPageChange($newPage: number): void {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;

    this.getData();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;

    this.getData();
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getData();
  }

  private getData() {
    if (this.typeTable === 'all_tasks') {
      this.getCosts();
    }
    if (this.typeTable === 'by_type') {
      this.getNumberTypeCosts();
    }
  }

  getExport() {
    let params: TasksFilter = {
      taskTypeId: this.taskTypeId ?? null,
    }
    this._taskService.listTaskFields$(this.tenantId, params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          let mappings: MappingModel = {
            mappings: [
              { sourceName: 'TaskType.Name', destName: this._translateService.instant('TaskType') },
              { sourceName: 'Code', destName: 'Code' },
              { sourceName: 'Title', destName: this._translateService.instant('Title') },
              { sourceName: 'Description', destName: this._translateService.instant('Description') },
              { sourceName: 'Headquarter.Name', destName: this._translateService.instant('Headquarter') },
              { sourceName: 'User.UserName', destName: this._translateService.instant('UserName') },
              { sourceName: 'Vehicle.LicensePlate', destName: this._translateService.instant('LicensePlate') },
              { sourceName: 'Asset.Code', destName: this._translateService.instant('AssetCode') },
              { sourceName: 'StartUser.UserName', destName: this._translateService.instant('StartUserName') },
              { sourceName: 'StartDate', destName: this._translateService.instant('StartDate') },
              { sourceName: 'EndUser.UserName', destName: this._translateService.instant('EndUserName') },
              { sourceName: 'EndDate', destName: this._translateService.instant('EndDate') },
              { sourceName: 'EndMileage', destName: this._translateService.instant('EndMileage') },
              { sourceName: 'ExpirationMileage', destName: this._translateService.instant('ExpirationMileage') },
              { sourceName: 'ExpirationDate', destName: this._translateService.instant('ExpirationDate') },
              { sourceName: 'AssignedUser.DisplayName', destName: this._translateService.instant('AssignedUserName') },

              { sourceName: 'TaskCost.AccountingDate', destName: this._translateService.instant('AccountingDate') },
              { sourceName: 'TaskCost.NetCost', destName: this._translateService.instant('NetCost') },
              { sourceName: 'TaskCost.GrossCost', destName: this._translateService.instant('GrossCost') },

              { sourceName: 'UsageStartDate', destName: this._translateService.instant('UsageStartDate') },
              { sourceName: 'UsageEndDate', destName: this._translateService.instant('UsageEndDate') },
            ]
          };

          if (response.items && response.items.length > 0) {
            response.items.forEach(field => {
              mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant('ExtraFields.' + field.name) });
            });
          }

          const queryParams: TasksFilter = {
            ...this.taskFilter,
            includeStartUser: true,
            includeEndUser: true
          }

          this._taskService.exportTasks$(this.tenantId, mappings, queryParams)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: (response) => {
                const filename = 'export_cost.xlsx';
                const blob = new Blob([response.body], { type: response.body.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                window.URL.revokeObjectURL(url);
              },
              error: (error) => {
                Sentry.captureEvent(error);
              }
            });
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
