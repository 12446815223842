<div class="section-5">
    <div class="card border-0 p-3 d-flex flex-column gap-3">
        <task-table-filters
            [userId]="userId"
            [hasManageTaskPermission]="hasManageTaskPermission"
            [listTableFilterTasks]="listTableFilterTasks"
            (filterTableChanged)="getTable($event)" 
            (filterTableHeaderChanged)="getDataForm($event)" 
            (exportEvent)="getExport()"
            (onMassiveAction)="onMassiveAction($event)">
        </task-table-filters>
        
        <dynamic-table
            [hasCheckbox]="hasManageTaskPermission"
            [customId]="'customId'"
            [columns]="tableColumns" [rows]="tableRows" (filterTasks)="getTable($event)"
            [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)" (emitTableRows)="onMassiveSelections($event)">
        </dynamic-table>
    </div>
</div>