import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, forkJoin, map, of, switchMap } from 'rxjs';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { BookingFilter } from 'src/app/shared/models/booking/booking-filter';
import { FringeBenefitUtilsService } from 'src/app/shared/utilities/fringe-benefit-utils.service';

@Component({
  selector: 'fringe-benefit-modal',
  templateUrl: './fringe-benefit-modal.component.html',
  styleUrls: ['./fringe-benefit-modal.component.scss']
})
export class FringeBenefitModalComponent implements OnInit {
  tenantId = this._tenantService.getTenantId();

  @Input() bookingId: string;
  data: ModalFringeBenefit;

  isLoading = false;

  private destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _vehiclesService: VehiclesService,
    private _fringeBenefitUtilsService: FringeBenefitUtilsService,
    private _activatedRoute: ActivatedRoute,
    private _datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.pipe(
      map(params => params['modalFringeBenefit'] as string),
    ).subscribe(params => {
      this.bookingId = params;
      if (this.bookingId) {
        this.getData();
      }
    });
  }

  private getData() {
    let params: BookingFilter = {
      includeVehicle: true
    };
    this.isLoading = true;
    this._bookingService.getBooking$(this.tenantId, this.bookingId, params).pipe(
      switchMap(response => {
        return forkJoin({
          booking: of(response),
          aciCost: this._fringeBenefitUtilsService.getAciCost(response.vehicle?.versionId),
        });
      })
    ).subscribe({
      next: ({ booking, aciCost }) => {
        let startDate = this._datePipe.transform(booking.startDate, 'dd MMM yyyy HH:mm');

        let endDate: string = null;
        if (booking.endDate) {
          endDate = this._datePipe.transform(booking.endDate, 'dd MMM yyyy HH:mm');
        }

        this.data = {
          topBody: {
            srcImage: this._vehiclesService.getVehicleImage$(this.tenantId, booking.vehicleId, 48, 48),
            title: booking.vehicle.displayName,
            subTitle: `${startDate} - ${endDate}`
          },

          aciCost: aciCost.toString(),
          fringeBenefitYear: this._fringeBenefitUtilsService.calculateFringeBenefitYear(aciCost),
          fringeBenefitMonth: this._fringeBenefitUtilsService.calculateFringeBenefitMonth(aciCost),
          tax: 30
        };
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
}

interface ModalFringeBenefit {
  topBody: {
    srcImage?: Observable<SafeUrl>;
    title: string;
    subTitle?: string;
  }

  aciCost?: string;
  fringeBenefitYear?: number;
  fringeBenefitMonth?: number;
  tax?: number;
}