import { APP_INITIALIZER, ErrorHandler, Injector, ModuleWithProviders, NgModule, inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';

import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';

import { VectoreAuthInterceptor } from './core/interceptors/vectoreAuth.interceptor';

import { TaskCreateModalModule } from './shared/components/modals/task-modal/task-create-modal/task-create-modal.module';
import { TaskModalEditModule } from './shared/components/modals/task-modal/task-edit-modal/task-edit-modal.module';
import { BookingCreateModalModule } from './shared/components/modals/booking-modal/booking-create-modal.module';

import { CustomTranslateLoader } from './core/services/custom-translate-loader.service';

import { map } from 'rxjs';
import { VECTORE_API_BASE_URL } from 'src/app/shared/constant/app-config';
import { TenantService } from './shared/api-services/tenant.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SentryErrorHandler } from './core/sentry-error-handler';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import { AuthService } from './shared/api-services/auth.service';


import { GoogleMapsModule } from '@angular/google-maps';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { deLocale, defineLocale, enGbLocale, frLocale, itLocale } from 'ngx-bootstrap/chronos';

export function setConfigSts(httpClient: HttpClient, injector: Injector) {
  const tenantService = injector.get(TenantService);
  const authService = injector.get(AuthService);

  const tenantId = tenantService.getTenantId();
  const config$ = authService.getIdp$(tenantId).pipe(
    map((customConfig: {
      clientId: string,
      authority: string,
      scope: string | null,
      vectoreAuth: boolean
    }) => {
      return {
        authority: customConfig.authority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: customConfig.clientId,
        scope: (customConfig.scope ?? 'openid'),
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Error,
        secureRoutes: [VECTORE_API_BASE_URL],
        disableIatOffsetValidation: true,
        autoUserInfo: false,
        disableIdTokenValidation: true,
      };
    })
  );
  return new StsConfigHttpLoader(config$);
};

export function getBrowserLanguage(translate: TranslateService, localeService: BsLocaleService) {
  return () => {
    let browserLang = translate.getBrowserLang();
    browserLang = browserLang.match(/it|en|fr|de/) ? browserLang : 'en';

    switch (browserLang) {
      case 'it':
        registerLocaleData(localeIt);
        defineLocale('it', itLocale);
        localeService.use('it');
        break;
      case 'fr':
        registerLocaleData(localeFr);
        defineLocale('fr', frLocale);
        localeService.use('fr');
        break;
      case 'de':
        registerLocaleData(localeDe);
        defineLocale('de', deLocale);
        localeService.use('de');
        break;
      default:
        registerLocaleData(localeEn); // Default to English
        defineLocale('en', enGbLocale);
        localeService.use('en');
        break;
    }

    translate.setDefaultLang('en');
    return translate.use(browserLang).toPromise();
  };
}

export function getLocale(translate: TranslateService): string {
  let browserLang = translate.getBrowserLang();
  return browserLang.match(/it|en|fr|de/) ? browserLang : 'en';
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomepageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) => new CustomTranslateLoader(httpClient,
          ['common', 'navbar', 'registration', 'homepage', 'dashboard', 'assets', 'persons', 'costs', 'tasks',
            'tasks-type', 'bookings', 'vehicles', 'vehicle-usages', 'import', 'invoices', 'suppliers', 'fleet-location', 'settings', 'notification', 'imports',
            'reports', 'cabinet', 'permissions'
          ]),
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    PaginationModule.forRoot(),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: setConfigSts,
        deps: [HttpClient, Injector],
      },
    }),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
  ],
  exports: [
    ModalModule,
    SharedModule,
    TranslateModule,
    BsDatepickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VectoreAuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getBrowserLanguage,
      deps: [TranslateService, BsLocaleService],
      multi: true
    },
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => getLocale(translate),
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}
