import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { HttpResponse } from '@angular/common/http';
import { Make } from 'src/app/shared/models/make/make';

@Component({
  selector: 'make-modal',
  templateUrl: './make-modal.component.html',
})
export class MakeModalComponent {

  tenantId: string = this._tenantService.getTenantId();

  make: Make;
  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.make?.id) {
      this.getMake();
    }
  }

  getMake() {
    this._makesService.getMake$(this.tenantId, this.make.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (make) => {
          this.form.patchValue({
            name: make.name
          });
          this.modalStatus.isCreate = false;
          this.modalStatus.isPatch = true;
        }
      });
  }

  createMake() {
    let request = this.createRequest();
    this._makesService.createMake$(this.tenantId, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  updateMake() {
    let request = this.createRequest();
    this._makesService.updateMake$(this.tenantId, this.make.id, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  private createRequest() {
    return {
      name: this.form.value.name
    };
  }

  deleteMake() {
    this.confirmModal = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'MESSAGES.CONFIRM_DELETE'
        }
      }
    });

    this.confirmModal.content.onConfirm
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this._makesService.deleteMake$(this.tenantId, this.make.id)),
      )
      .subscribe({
        next: (response: HttpResponse<Object>) => {
          if (response.status === 204) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
