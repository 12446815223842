<div
  class="col"
  id="accordionExample"
  [ngClass]="{ card: !isMobile, accordion: isMobile }"
>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button
        class="accordion-button title"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {{ "BOOKING" | translate }}
      </button>
    </h2>

    <div
      [id]="{ collapseOne: !isMobile }"
      class="accordion-body collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div class="container-booking-information">
        <div class="input-group d-flex align-items-center mb-2">
          <label for="status">{{ "STATUS" | translate }}</label>
          <dropdown-primary
            [emitNgOnInit]="false"
            [isDisabled]="isDisableStatus || !isFleetManager"
            [options]="listStatus"
            [selectedOption]="optionStatusBooking"
            (selectedOptionChange)="onBookingStatusChange($event)"
            [cssClass]="
              dropdownApprovationCssClass + ' border-0 custom-badge-bold'
            "
            [iconClass]="iconForStatus"
          >
          </dropdown-primary>
        </div>

        <div class="input-group">
          <label for="startDate">{{ "START_DATE" | translate }}</label>
          <span>{{ booking?.startDate | date : "d MMM yyyy HH:mm" }}</span>
        </div>

        <div class="input-group">
          <label for="endDate">{{ "END_DATE" | translate }}</label>
          <span>{{ booking?.endDate | date : "d MMM yyyy HH:mm" }}</span>
        </div>

        <div class="input-group">
          <label for="bookingType">{{ "TYPOLOGY" | translate }}</label>
          <span>{{ booking?.bookingType?.name }}</span>
        </div>

        <div class="input-group">
          <label for="vehicle">{{ "VEHICLE" | translate }}</label>
          <span>{{ booking?.vehicle?.licensePlate }}</span>
        </div>

        <div class="input-group">
          <label for="driver">{{ "BOOKINGS.DRIVER" | translate }}</label>
          <span>{{ booking?.user?.displayName }}</span>
        </div>

        <div class="input-group">
          <label for="inserted-by-user">{{
            "BOOKINGS.INSERTED_BY_USER" | translate
          }}</label>
          <span>{{ booking?.insertedByUser?.displayName }}</span>
        </div>
      </div>

      <div class="container-location-information">
        <h3 class="title py-2">{{ "LOCATION.NAME" | translate }}</h3>

        <div class="input-group">
          <label for="headerquarter">{{
            "HEADQUARTER.NAME" | translate
          }}</label>
          <span>{{ booking?.headquarter?.name }}</span>
        </div>

        <div class="input-group">
          <label for="location">{{ "LOCATION.NAME" | translate }}</label>
          <span>{{ booking?.location?.name }}</span>
        </div>
      </div>

      <div class="container-booking-extrafields" *ngIf="extraFields">
        <h3 class="title py-2">{{ "INFORMATION" | translate }}</h3>

        <table>
          <tr *ngFor="let key of objectKeys(extraFields)">
            <td class="span">{{ key }}</td>
            <td>{{ extraFields[key] }}</td>
          </tr>
        </table>
      </div>

      <button
        class="btn-gs-primary-noborder mt-3"
        role="button"
        (click)="openModalEditBooking()"
        [disabled]="!(booking?.status.split('_')[0] !== 'CLOSED')"
      >
        {{ "ACTIONS.EDIT" | translate }}
      </button>
    </div>
  </div>
</div>
