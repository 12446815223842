import * as Sentry from "@sentry/angular-ivy";
import * as Permissions from 'src/app/shared/constant/permissions';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';

import { BookingService } from "src/app/shared/api-services/booking.service";
import { Booking, BookingClient } from "src/app/shared/models/booking/booking";
import { BookingResponse, BookingUsageResponse } from "src/app/shared/models/booking/booking-response";
import { MappingModel } from "src/app/shared/models/user/users";
import { TableColumn } from "src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-column.interface";
import { TableRow } from "src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-row.interface";
import { BookingUtilsService } from "src/app/shared/utilities/booking-utils.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Me } from "src/app/shared/models/me/me";
import { MeService } from "src/app/shared/api-services/me.service"; import { BookingsFilter } from "src/app/shared/models/booking/booking-filter";
import { UsersService } from "src/app/shared/api-services/users.service";
import { VehiclesService } from "src/app/shared/api-services/vehicles.service";

@Component({
  selector: 'booking-table',
  templateUrl: './booking-table.component.html',
  styleUrls: ['./booking-table.component.scss']
})
export class BookingTableComponent implements OnInit, OnDestroy {
  tenantId: string;

  userId: string;
  vehicleId: string;
  bookingTypeId: string;
  headquarterId: string;
  locationId: string;

  @Input() titleTable: string;
  @Input() isfleetManager: boolean = false;

  tableColumns: TableColumn[];
  tableRows: TableRow[];

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  filterHeader: string;
  filterTask: DropdownOption;

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;


  isMobile: boolean = false;
  hasManageBookingsPermission: boolean = false;
  permissions: string[] = [];

  startDate: string;
  endDate: string;

  bookings: Booking[];

  confirmModalDelete: BsModalRef;
  confirmModalClose: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _meService: MeService,
    private _usersService: UsersService,
    private _vehiclesService: VehiclesService,
    private _modalUtilsService: ModalUtilsService,
    private _translateService: TranslateService,
    private _datePipe: DatePipe,
    private _bookingUtilsService: BookingUtilsService,
    private _filterModalService: FilterModalService,
    private _dropdownEventsService: DropdownEventsService,
    private _toastrService: ToastrService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this.tenantId = this._tenantService.getTenantId();
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.checkPermissions();
    this.getTable();
    this.applyFilterToTable();

    this._communicationService.getEvent()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getBookings();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  checkPermissions() {
    this._meService.getMePermissions$(this.tenantId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.permissions = response.permissions;
      this.hasManageBookingsPermission = response.permissions.includes(Permissions.CAN_MANAGE_BOOKINGS);
      if (!this.hasManageBookingsPermission) {
        this.tableColumns = this.tableColumns.filter(column => column.field !== 'actionsDynamic');
      }
    });
  }

  getTable() {
    this.getBookings();

    this.tableColumns = [];
    if (!this.isMobile) {
      this.tableColumns = [
        {
          field: 'title', header: "TITLE",
          routerLink: (booking: Booking) => `booking-detail/${booking.id}`,
          cssClass: () => 'cursor-pointer',
        },
        {
          field: 'userId', isSortable: true,
          header: "BOOKINGS.DRIVER",
          value: (booking: Booking) => booking.user?.displayName,
          hasAvatar: true,
          avatar: (booking: Booking) => booking.user?.srcImage$,
        },
        {
          field: 'bookingTypeId', isSortable: true,
          header: "TYPE",
          value: (booking: Booking) => booking.bookingType?.name
        },
        {
          field: 'startDate', isSortable: true,
          header: "START_DATE",
          value: (booking: BookingClient) => booking.startDateClient?.text,
          cssClass: (booking: BookingClient) => booking.startDateClient?.cssClass
        },
        {
          field: 'endDate', isSortable: true,
          header: "END_DATE",
          value: (booking: BookingClient) => booking.endDateClient?.text,
          cssClass: (booking: BookingClient) => booking.endDateClient?.cssClass
        },
        {
          field: 'vehicleId', isSortable: true,
          header: "VEHICLE",
          value: (booking: Booking) => booking.vehicle?.licensePlate,
          hasAvatar: true,
          avatar: (booking: Booking) => booking.vehicle?.srcImage$,
        },
        {
          field: 'headquarterId', isSortable: true,
          header: "HEADQUARTER.NAME",
          value: (booking: Booking) => booking.headquarter?.name
        },
        {
          field: 'locationId', isSortable: true,
          header: "LOCATION.NAME",
          value: (booking: Booking) => booking.location?.name
        },
        {
          field: 'insertedByUserId', isSortable: true,
          header: "BOOKINGS.INSERTED_BY_USER",
          value: (booking: Booking) => booking.insertedByUser?.displayName,
          hasAvatar: true,
          avatar: (booking: Booking) => booking.insertedByUser?.srcImage$,
        },
        {
          field: 'status',
          header: "STATUS",
          value: (booking: BookingClient) => booking.statusClient?.text,
          tooltip: (booking: BookingClient) => booking.statusClient?.tooltip,
          cssClass: (booking: BookingClient) => booking.statusClient?.cssClass
        },
        {
          field: 'actionsDynamic', isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (booking: BookingClient) => booking.actions
        }
      ];
    }
    this._cdr.detectChanges();

  }

  getBookings() {
    let params: BookingsFilter = {
      includeUser: true,
      includeBookingType: true,
      includeHeadquarter: true,
      includeLocation: true,
      includeVehicle: true,
      includeInsertedByUser: true,
      includeApprovedByUser: true,
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
      rangeStart: this.startDate ? new Date(new Date(this.startDate).setHours(0, 0, 0, 0)).toISOString() : null,
      rangeEnd: this.endDate ? new Date(new Date(this.endDate).setHours(23, 59, 59, 999)).toISOString() : null,
    }

    if (this.userId) {
      params.userId = this.userId;
    }

    if (this.vehicleId) {
      params.vehicleId = this.vehicleId;
    }

    if (this.bookingTypeId) {
      params.bookingTypeId = this.bookingTypeId;
    }

    if (this.headquarterId) {
      params.headquarterId = this.headquarterId;
    }

    if (this.locationId) {
      params.locationId = this.locationId;
    }

    this.applyFilter(params);

    if (!this.isfleetManager) {
      this._meService.getMe$(this.tenantId).pipe(
        takeUntil(this.destroy$),
        switchMap((me: Me) => {
          this.userId = me.id;
          params.userId = this.userId;
          return this._bookingService.listBookings$(this.tenantId, params);
        })
      ).subscribe({
        next: (bookings: BookingResponse) => {
          this.setTableData(bookings);
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
    else {
      this._bookingService.listBookings$(this.tenantId, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (bookings: BookingResponse) => {
            this.setTableData(bookings);
          },
          error: (error) => {
            Sentry.captureEvent(error);
          }
        });
    }
  }

  private setTableData(bookings: BookingResponse) {
    this.tableRows?.forEach((row) => {
      this._dropdownEventsService.removeSubscriptions(row.id);
    });
    this.tableRows = [];

    bookings.items?.forEach((booking) => {
      booking = this._bookingUtilsService.setTitleBooking(booking);
      booking = this._bookingUtilsService.setDateBooking(booking);
      booking = this._bookingUtilsService.setStatusBadgeBooking(booking);
      booking = this._bookingUtilsService.setActionsBookings(booking, this.permissions);
      if (booking.user) {
        booking.user.srcImage$ = this._usersService.getUserImage$(this.tenantId, booking.user?.id, 20, 20);
      }
      if (booking.vehicle) {
        booking.vehicle.srcImage$ = this._vehiclesService.getVehicleImage$(this.tenantId, booking.vehicle?.id, 20, 20);
      }
      if (booking.insertedByUser) {
        booking.insertedByUser.srcImage$ = this._usersService.getUserImage$(this.tenantId, booking.insertedByUser?.id, 20, 20);
      }
    });

    this.tableRows = [];
    this.tableRows = bookings.items;
    this.bookings = bookings.items;

    this.onActionTable();

    this.filtersPage.totalRows = bookings.count;
    this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
  }

  private applyFilter(params: any) {
    if (this.fitersTable) {
      if (this.fitersTable.value.startDate) {
        let startDate = new Date(this.fitersTable.value.startDate);
        startDate.setHours(0, 0, 0, 0);
        this.startDate = startDate.toISOString();
        params.rangeStart = this.startDate;
      }
      else {
        this.startDate = null;
        params.rangeStart = this.startDate;
      }

      if (this.fitersTable.value.endDate) {
        let endDate = new Date(this.fitersTable.value.endDate);
        endDate.setHours(23, 59, 59, 999);
        this.endDate = endDate.toISOString();
        params.rangeEnd = this.endDate;
      }
      else {
        this.endDate = null;
        params.rangeEnd = this.endDate;
      }

    }
  }

  getDataForm($event: FormGroup) {
    this.fitersTable = $event;
    this.getBookings();
  }

  onActionTable() {
    this.tableRows.forEach((row) => {
      this._dropdownEventsService.getSelectedOptionOnClick(row.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (option) => {

            if (option && option.value == 'edit') {
              let params = {
                includeUser: true,
                includeVehicle: true,
                includeLocation: true,
                includeHeadquarter: true,
                includeBookingType: true,
                includeInsertedByUser: true,
              };

              this._bookingService.getBooking$(this.tenantId, row.id, params)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (booking: Booking) => {
                    this._modalUtilsService.openBookingModal(booking, this.isfleetManager)
                      .pipe(takeUntil(this.destroy$))
                      .subscribe();
                  },
                  error: (error: any) => {
                    Sentry.captureEvent(error);
                  }
                });
            }

            if (option && option.value == 'delete') {
              this._bookingService.deleteBooking$(this.tenantId, row.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (response) => {
                    if (response.status == 204) {
                      this.getBookings();
                      this._communicationService.sendEvent();
                      this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_DELETED'));
                    }
                  }
                });
            }

            if (option && option.value == 'detail') {
              this._router.navigate([`booking-detail/${row.id}`], { relativeTo: this._activeRoute });
            }

            if (option && option.value == 'approve') {
              this._bookingService.approveBooking$(this.tenantId, row.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (response) => {
                    if (response.status == 200) {
                      this.getBookings();
                      this._communicationService.sendEvent();
                      this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_APPROVED'));
                    }
                  }
                });
            }

            if (option && option.value == 'unapprove') {
              this._bookingService.unapproveBooking$(this.tenantId, row.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (response) => {
                    if (response.status == 200) {
                      this.getBookings();
                      this._communicationService.sendEvent();
                      this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_UNAPPROVED'));
                    }
                  }
                });
            }

            if (option && option.value == 'close') {
              this._bookingService.listBookingUsages$(this.tenantId, row.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (response: BookingUsageResponse) => {
                    let checkBookingUsageActive = response.items.some(usage => usage.endDate == null);
                    this.confirmModalClose = this._modalService.show(ConfirmModalComponent, {
                      class: 'modal-md',
                      animated: true,
                      initialState: {
                        data: {
                          title: this._translateService.instant('BOOKINGS.MODAL_CLOSE_BOOKING.TITLE'),
                          titleCssClass: 'text-danger',
                          content: checkBookingUsageActive ? this._translateService.instant('BOOKINGS.MODAL_CLOSE_BOOKING.CONTENT_USAGES') : this._translateService.instant('BOOKINGS.MODAL_CLOSE_BOOKING.CONTENT_NO_USAGES'),
                          btnCssClassYes: 'btn-gs-danger',
                          btnCssClassNo: 'btn-gs-secondary',
                        }
                      }
                    });
                    this.confirmModalClose.content.onConfirm
                      .pipe(
                        takeUntil(this.destroy$),
                        switchMap(() => {
                          return this._bookingService.closeBooking$(this.tenantId, row.id)
                        }),
                        tap((response: Booking) => {
                          if (response.id) {
                            this._communicationService.sendEvent();
                          }
                        })
                      )
                      .subscribe();
                  }
                });


            }
          }
        );
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmModalDelete = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          data: {
            content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', { x: elementsSelected.length }),
          }
        }
      });

      this.confirmModalDelete.content.onConfirm.pipe(
        takeUntil(this.destroy$),
      )
        .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._bookingService.deleteBooking$(this.tenantId, elementsSelected[index].id)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (response) => {
                  if (response.status == 204) {
                    if (index === elementsSelected.length - 1) {
                      this.getBookings();
                    }
                    this._toastrService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_DELETED'));
                  }
                }
              });
          }
        });
    }
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getBookings();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getBookings();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getBookings();
  }

  private applyFilterToTable() {
    this._filterModalService.getForm$('filter-booking-modal')
      .pipe(takeUntil(this.destroy$))
      .subscribe(form => {
        if (form) {
          this.bookingTypeId = form.value.bookingTypeId ?? null;
          this.vehicleId = form.value.vehicleId ?? null;
          this.headquarterId = form.value.headquarterId ?? null;
          this.locationId = form.value.locationId ?? null;
          this.getBookings();
        }
      });
  }

  getExport() {
    this._bookingService.getBookingFields$(this.tenantId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          let mappings: MappingModel = {
            mappings: [
              { sourceName: 'Title', destName: this._translateService.instant('TITLE') },
              { sourceName: 'User.DisplayName', destName: this._translateService.instant('BOOKINGS.DRIVER') },
              { sourceName: 'BookingType.Name', destName: this._translateService.instant('TYPE') },
              { sourceName: 'StartDate', destName: this._translateService.instant('START_DATE') },
              { sourceName: 'EndDate', destName: this._translateService.instant('END_DATE') },
              { sourceName: 'Vehicle.LicensePlate', destName: this._translateService.instant('VEHICLE') },
              { sourceName: 'Headquarter.Name', destName: this._translateService.instant('HEADQUARTER.NAME') },
              { sourceName: 'Location.Name', destName: this._translateService.instant('LOCATION.NAME') },
              { sourceName: 'InsertedByUser.DisplayName', destName: this._translateService.instant('BOOKINGS.INSERTED_BY_USER') },
            ]
          };

          if (response.items && response.items.length > 0) {
            response.items.forEach(field => {
              mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant(field.name) });
            });
          }

          let query = {
            includeUser: true,
            includeVehicle: true,
            includeLocation: true,
            includeHeadquarter: true,
            includeBookingType: true,
            includeInsertedByUser: true,
          }

          this._bookingService.exportBookings$(this.tenantId, mappings, query)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (response) => {
                const filename = 'export_booking.xlsx';
                const blob = new Blob([response.body], { type: response.body.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                window.URL.revokeObjectURL(url);
              },
              error: (error) => {
                Sentry.captureEvent(error);
              }
            });
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
