<div class="container-table">
    <div class="card border-0 p-3">

        <booking-table-filters
            [titleTable]="titleTable"
            [hasManageBookingsPermission]="hasManageBookingsPermission"
            (filterTableHeaderChanged)="getDataForm($event)" 
            (exportEvent)="getExport()"
            (onMassiveAction)="onMassiveAction($event)">
        </booking-table-filters>

        <dynamic-table *ngIf="!isMobile; else mobileTable"
            [hasCheckbox]="hasManageBookingsPermission"
            [columns]="tableColumns" [rows]="tableRows" (filterTasks)="getTable()"
            [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)" (emitTableRows)="onMassiveSelections($event)">
        </dynamic-table>

        <ng-template #mobileTable>
            <dynamic-table-mobile-bookings [bookings]="bookings" [totalItems]="filtersPage.totalRows"
                [itemsPerPage]="filtersPage.itemsPerPage" [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination"
                (pageChanged)="onPageChange($event)">
            </dynamic-table-mobile-bookings>
        </ng-template>

    </div>
</div>
