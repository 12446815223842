import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImportService } from 'src/app/shared/api-services/import.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { ImportField, Importer, MappingExport, RelatedField } from 'src/app/shared/models/import/import';
import { TranslateService } from '@ngx-translate/core';
import { TypeImporters } from '../upload-import-modal/type-importers';

@Component({
  selector: 'download-import-modal',
  templateUrl: './download-import-modal.component.html',
  styleUrls: ['./download-import-modal.component.scss']
})
export class DownloadImportModalComponent {

  tenantId = this._tenantService.getTenantId();

  @Input() typeImporters: TypeImporters;

  typeImportersOption: DropdownOption[];
  selectedTypeImporter: DropdownOption;

  listImporters: Importer[];
  typeImporter: Importer;

  firstFilters: string[] = [];
  secondFilters: string[] = [];

  filter: any;

  canDownload = false;

  constructor(
    private _tenantService: TenantService,
    private _importService: ImportService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.getListTypeImporters();
  }

  private getListTypeImporters() {
    this._importService.listImporters$(this.tenantId).subscribe({
      next: response => {
        this.listImporters = response.items;
        this.typeImportersOption = response.items.map(importer => {
          return {
            value: importer.name,
            text: importer.name,
          }
        }).sort((a, b) => a.text.localeCompare(b.text));;
        this.selectedTypeImporter = { value: '', text: 'SELECT_OPTION' };
        this.selectTypeImporter();
      }
    });
  }

  onSelectedTypeImporter($event: DropdownOption) {
    this.selectedTypeImporter = $event;
    this.typeImporter = this.listImporters.find(importer => importer.name === $event.value);
    this.filter = {};
    if (this.typeImporter){
      this.canDownload = true;
    }
  }

  selectTypeImporter() {
    switch (this.typeImporters) {
      case TypeImporters.Versions:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Versions);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Vehicles:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Vehicles);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Users:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Users);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Assets:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Assets);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Tasks:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Tasks);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.VehicleUsages:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.VehicleUsages);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      default:
        this.selectedTypeImporter = { value: '', text: 'SELECT_OPTION' };
        break;
    }
  }

  downloadTrace() {
    if (this.typeImporter) {

      this._importService.listImporterFields$(this.tenantId, this.typeImporter.name).subscribe({
        next: (response : ImportField[]) => {

          let relatedFields: RelatedField = response.reduce((acc, field) => {
            if (field.name.split('_')[0] === 'ExtraFields.') {
              acc[field.name] = this._translateService.instant(field.name);
            } else {
              acc[field.name] = this._translateService.instant(field.name);
            }
            return acc;
          }, {} as RelatedField);

          let params : MappingExport = {
            mappings: relatedFields,
            values: this.filter
          }

          this._importService.createTemplate$(this.tenantId, this.typeImporter.name, params).subscribe({
            next: response => {
              const url = window.URL.createObjectURL(response.body);
    
              const a = document.createElement('a');
              a.href = url;
              a.download = this.selectedTypeImporter.value + '.xlsx';
              document.body.appendChild(a);
              a.click();
    
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }
          });

        }
      });
    }
  }

  onFilterSelected($event: DropdownOption, specificFilter? : string) {

    if (this.selectedTypeImporter.value === 'assets') {
      this.filter['AssetType'] = $event.extraField?.text;
    }

    if (this.selectedTypeImporter.value === 'tasks') {
      this.filter['TaskType'] = $event.extraField?.text;
    }

    if ((this.selectedTypeImporter.value === 'vehicles' && specificFilter === 'VehicleType') || this.selectedTypeImporter.value === 'versions') {
      this.filter['VehicleType'] = $event.extraField?.text;
    }

    if (this.selectedTypeImporter.value === 'vehicles' && specificFilter === 'PurchaseType') {
      this.filter['PurchaseType'] = $event.extraField?.text;
    }

    if (this.selectedTypeImporter.value === 'versions') {
      this.filter['VehicleType'] = $event.extraField?.text;
    }
  }
}
