import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { VECTORE_API_BASE_URL } from '../constant/app-config';

import { CommonService } from './common.service';
import { Import, ImportField, ImportRow, Importer, MappingExport } from '../models/import/import';
import { ImporterResponse, ImportResponse, ImportRowResponse } from '../models/import/import-response';
import { CreateImportRequest, PatchImportRequest, PatchImportRowRequest } from '../models/import/import-request';
import { ImportRowsFilter, ImportsFilter } from '../models/import/import-filter';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
  ) { }

  listImporters$(tenantId: string): Observable<ImporterResponse> {
    return this._http.get<ImporterResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/importers`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImporter$(tenantId: string, importerName: string): Observable<Importer> {
    return this._http.get<Importer>(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImporterFields$(tenantId: string, importerName: string): Observable<ImportField[]> {
    return this._http.get<ImportField[]>(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}/fields`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createTemplate$(tenantId: string, importerName: string, mapping: MappingExport): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/importers/${importerName}/template`, mapping,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap({
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  createImport$(tenantId: string, importRequest: CreateImportRequest): Observable<Import> {
    return this._http.post<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports`, importRequest)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImports$(tenantId: string, filter: ImportsFilter): Observable<ImportResponse> {
    return this._http.get<ImportResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/imports`,
      { params: this._commonService.buildParams(filter) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImport$(tenantId: string, importId: string): Observable<Import> {
    return this._http.get<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  patchImport$(tenantId: string, importId: string, patchImportRequest: PatchImportRequest): Observable<Import> {
    return this._http.patch<Import>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`, patchImportRequest);
  }

  deleteImport$(tenantId: string, importId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImportRow$(tenantId: string, importId: string, importRowId: string): Observable<ImportRow> {
    return this._http.get<ImportRow>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows/${importRowId}`);
  }

  patchImportRow$(tenantId: string, importId: string, importRowId: string, patchImportRowRequest: PatchImportRowRequest): Observable<ImportRow> {
    return this._http.patch<ImportRow>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows/${importRowId}`, patchImportRowRequest);
  }

  listImportRows$(tenantId: string, importId: string, filter: ImportRowsFilter): Observable<ImportRowResponse> {
    return this._http.get<ImportRowResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/imports/${importId}/rows`,
      { params: this._commonService.buildParams(filter) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }
}
